<template>
  <div class="animated fadeIn">
    Page not found
<!--
    <div class="right">
      <b-form :inline="true" style="top:10px">
        <b-input-group  left="เริ่ม" right="<i class='fa fa-calendar'></i>">
          <b-form-input v-model="startDate" type="date"></b-form-input>
        </b-input-group>
        <b-input-group left="ถึง" right="<i class='fa fa-calendar'></i>">
          <b-form-input v-model="lastDate" type="date"></b-form-input>
        </b-input-group>
        <b-button v-on:click="searchFn()" variant="primary" :active="true">ค้นหา</b-button>
      </b-form>
    </div>
    <b-button variant="danger" :active="true" @click="Monthly()">Monthly</b-button>
    <b-button variant="primary" :active="true" @click="Weekly()">Weekly</b-button>
    <b-button variant="success" :active="true" @click="Daily()">Daily</b-button>

      

    <div class="wrapper">
      <div class="row">
        <div class="col-12">
          <b-card style="top:15px;">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Machine Quantity</th>
                  <th>Order</th>
                  <th>Revenue</th>
                </tr>
              </thead>
              <template v-for="item in rowData">
                <tbody> 
                  <tr>
                    <td>{{item.id}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.machineqty}}</td>
                    <td>{{item.order}}</td>
                    <td>{{item.revenue}}</td>
                  </tr>
                </tbody>
              </template>
            </table> 
            <uib-pagination :total-items="totalRows" :items-per-page="20" v-model="pagination" class="pagination-sm" @change="pageChanged(pagination.currentPage)"></uib-pagination>
          </b-card>
        </div>
      </div>
    </div>
-->
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
export default {
  name: 'SalesByDealer',
  components: {
    Datepicker
  },
  data () {
    return {
      test: this.$store.state.token,
      totalRows: 1,
      pagination: {},
      startDate: '',
      lastDate: '',
      rowData: [
        {
          id: '000001',
          name: 'USER A',
          machineqty: '4',
          order: '84',
          revenue: '5430'
        },
        {
          id: '000001',
          name: 'USER B',
          machineqty: '11',
          order: '297',
          revenue: '12730'
        },
        {
          id: '000001',
          name: 'USER C',
          machineqty: '9',
          order: '254',
          revenue: '10040'
        },
        {
          id: '000001',
          name: 'USER D',
          machineqty: '7',
          order: '159',
          revenue: '8320'
        }
      ]
    }
  },
  methods: {
    linkGen (PageNum) {
    },
    searchFn () {
      console.log(`Select ${this.startDate} to ${this.lastDate}`)
    }
  }
}
</script>

<style>
  @import './styleRe.css'
</style>
